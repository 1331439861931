<template>
  <div class="page-header-index-wide">
    <a-card :bordered="false" :bodyStyle="{ padding: '16px 0', height: '100%' }" :style="{ height: '100%' }">
      <div class="account-settings-info-main">
        <div class="account-settings-info-left">
          <a-menu
            :mode="mode"
            :style="{ border: '0', width: 'auto'}"
            :defaultSelectedKeys="defaultSelectedKeys"
            :selectedKeys="selectedKeys"
            type="inner"
            @openChange="onOpenChange">
            <a-menu-item :key="item.path" v-for="item in menuItems">
              <router-link :to="{ path: item.path }">
                {{item.meta.title}}
              </router-link>
            </a-menu-item>
          </a-menu>
        </div>
        <div class="account-settings-info-right">
          <div class="account-settings-info-title">
            <span>{{ selectedMenu.meta.title }}</span>
          </div>
          <route-view></route-view>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>

  import RouteView from '@/components/layouts/RouteView'
  import { findMenu } from '../../utils/util'
  import { mapState } from 'vuex'

  export default {
    components: {
      RouteView
    },
    data () {
      return {
        // horizontal  inline
        mode: 'inline',

        openKeys: [],
        defaultSelectedKeys: [],
        selectedKeys: [],
        menuItems: [],
        selectedMenu: { meta: {} },

        // cropper
        preview: {},
        option: {
          img: '/avatar2.jpg',
          info: true,
          size: 1,
          outputType: 'jpeg',
          canScale: false,
          autoCrop: true,
          // 只有自动截图开启 宽度高度才生效
          autoCropWidth: 180,
          autoCropHeight: 180,
          fixedBox: true,
          // 开启宽度和高度比例
          fixed: true,
          fixedNumber: [1, 1]
        },

        pageTitle: ''
      }
    },
    created () {
      this.updateMenu()
      this.menuItems = this.leftMenu()
      this.selectedMenu = findMenu(this.mainMenu, this.defaultSelectedKeys[0], 3)
    },
    watch: {
      '$route.path' () {
        this.selectedMenu = findMenu(this.mainMenu, this.$route.path, 3)
        this.selectedKeys = [this.$route.path]
      }
    },
    computed: {
      ...mapState({
        // 主路由
        mainMenu: state => state.user.columnItems
      })
    },
    methods: {
      leftMenu () {
        const menu = findMenu(this.mainMenu, this.defaultSelectedKeys[0], 3)
        return menu.parent.children || []
      },
      onOpenChange (openKeys) {
        this.openKeys = openKeys
      },
      updateMenu () {
        const routes = this.$route.matched.concat()
        this.defaultSelectedKeys = [routes.pop().path]
        this.selectedKeys = this.defaultSelectedKeys
      }
    }
  }
</script>

<style lang="less" scoped>
  .account-settings-info-main {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: auto;

    &.mobile {
      display: block;

      .account-settings-info-left {
        border-right: unset;
        border-bottom: 1px solid #e8e8e8;
        width: 100%;
        height: 50px;
        overflow-x: auto;
        overflow-y: scroll;
      }

      .account-settings-info-right {
        padding: 20px 40px;
      }
    }

    .account-settings-info-left {
      border-right: 1px solid #e8e8e8;
      width: 224px;
    }

    .account-settings-info-right {
      flex: 1 1;
      padding: 8px 40px;

      .account-settings-info-title {
        color: rgba(0, 0, 0, .85);
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 12px;
      }

      .account-settings-info-view {
        padding-top: 12px;
      }
    }
  }

</style>
